import {
    CANCEL_SUBSCRIPTION_SUCCESS,
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_ROLE_FAILED,
    GET_USER_ROLE_SUCCESS,
    GET_USERNAME,
    GET_USERNAME_SUCCESS,
    GET_USERS,
    GET_USERS_SUCCESS,
    ManageUsersActionTypes,
    ManageUsersState,
    REFRESH_SUBSCRIPTIONS,
    REFRESH_SUBSCRIPTIONS_SUCCESS,
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    UserRole,
    GET_USER_QUERY_TYPES_SUCCESS,
    FETCH_SUBSCRIPTION_EVENTS_SUCCESS,
    SEARCH_USER_FAILED,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    GET_USER_DETAILS_FAILED,
    UsersFetchResponse,
    SEARCH_CONTEXT,
    MIGRATE_USER,
    MIGRATE_USER_SUCCESS,
    MIGRATE_USER_FAILED,
    TOGGLE_SOFT_MIGRATION_SUCCESS,
    GET_SOFT_MIGRATION_STATUS_SUCCESS,
    TOGGLE_SOFT_MIGRATION,
    TOGGLE_SOFT_MIGRATION_FAILED,
    FETCH_PAYMENT_TRANSACTIONS_SUCCESS,
    ADD_FREE_SUBSCRIPTION,
    ADD_FREE_SUBSCRIPTION_SUCCESS,
    ADD_FREE_SUBSCRIPTION_FAILED,
} from "./types";

const initialState: ManageUsersState = {
    users: null,
    noUsersFound: false,
    hasMoreUsers: true,
    user: null,
    username: undefined,
    userRoleChecked: false,
    userRole: undefined,
    isDeletingUser: false,
    userUpdated: false,
    userQueryTypes: [],
    userNotFound: false,
    [SEARCH_CONTEXT.UsersTable]: null,
    [SEARCH_CONTEXT.ManualMigration]: null,
    manualMigrationStatus: "idle",
    softMigrationStatus: "idle",
    isRedeemingVoucher: false,
};

function ManageUsersReducer(state = initialState, action: ManageUsersActionTypes): ManageUsersState {
    switch (action.type) {
        case GET_USERNAME:
            return { ...state };

        case GET_USER_ROLE_SUCCESS:
            return {
                ...state,
                userRoleChecked: true,
                userRole: UserRole[capitalize(action.userRole) as keyof typeof UserRole],
            };

        case GET_USER_ROLE_FAILED: {
            return {
                ...state,
                userRoleChecked: true,
            };
        }

        case GET_USER_QUERY_TYPES_SUCCESS: {
            return {
                ...state,
                userQueryTypes: action.queryTypes,
            };
        }

        case GET_USERNAME_SUCCESS:
            return {
                ...state,
                username: action.username,
            };

        case GET_USERS:
            return {
                ...state,
                noUsersFound: false,
                [SEARCH_CONTEXT.UsersTable]: {
                    ...state[SEARCH_CONTEXT.UsersTable],
                    isLoading: true,
                },
            };

        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: {
                    userAccounts: [...(state.users?.userAccounts ?? []), ...((action.users as UsersFetchResponse).userAccounts ?? [])],
                },
                hasMoreUsers: action.users.userAccounts ? action.users.userAccounts.length > 0 : false,
                [SEARCH_CONTEXT.UsersTable]: {
                    ...state[SEARCH_CONTEXT.UsersTable],
                    isLoading: false,
                },
            };

        case SEARCH_USER:
            return { ...state, [action.context]: { noUsersFound: false, searchedUsers: null, isLoading: true } };
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                [action.context]: {
                    noUsersFound: action.users.userAccounts.length === 0,
                    searchedUsers: action.users,
                    isLoading: false,
                },
            };
        case SEARCH_USER_FAILED:
            return {
                ...state,
                [action.context]: {
                    noUsersFound: true,
                    searchedUsers: null,
                    isLoading: false,
                },
            };

        case GET_USER_DETAILS:
            return {
                ...state,
                user: null,
                userNotFound: false,
                manualMigrationStatus: "idle",
            };

        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                user: action.user,
                userNotFound: false,
            };

        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                userNotFound: action.status !== 500,
            };

        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userUpdated: true,
            };

        case REFRESH_SUBSCRIPTIONS:
            return {
                ...state,
                userUpdated: false,
            };
        case REFRESH_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                userUpdated: true,
            };

        case FETCH_SUBSCRIPTION_EVENTS_SUCCESS:
            if (state.user) {
                const updatedAccounts = state.user.accounts.map((acc) => {
                    if (acc.userAccountId === action.userAccountId) {
                        const updatedSubscriptions = acc.subscriptions.map((sub) => {
                            if (sub.subscriptionId === action.subscriptionId) {
                                return {
                                    ...sub,
                                    subscriptionEvents: action.data,
                                };
                            }
                            return sub;
                        });

                        return {
                            ...acc,
                            subscriptions: updatedSubscriptions,
                        };
                    }
                    return acc;
                });

                return {
                    ...state,
                    user: {
                        ...state.user,
                        accounts: updatedAccounts,
                    },
                };
            }

            return state;

        case FETCH_PAYMENT_TRANSACTIONS_SUCCESS:
            state.user?.accounts?.find(
                (acc) =>
                    acc?.subscriptions
                        ?.filter((sub) => sub.subscriptionId === action.subscriptionId)
                        .map((sub) => {
                            sub.paymentTransactions = action.data;
                        })
            );

            return state;

        case DELETE_USER:
            return {
                ...state,
                isDeletingUser: true,
            };

        case DELETE_USER_SUCCESS:
            if (!state.user) return state;

            const filteredUsers = state.user.accounts.filter((user) => user.userAccountId !== action.deletedUserAccountId);

            return {
                ...state,
                user: { ...state.user, accounts: filteredUsers },
                isDeletingUser: false,
            };

        case DELETE_USER_FAILED:
            if (!state.user) return state;

            const filteredUsersArr = state.user.accounts.filter((user) => user.userAccountId !== action.deletedUserAccountId);

            return {
                ...state,
                user: { ...state.user, accounts: filteredUsersArr },
                isDeletingUser: false,
            };

        case MIGRATE_USER:
            return {
                ...state,
            };

        case MIGRATE_USER_SUCCESS:
            return {
                ...state,
                manualMigrationStatus: "success",
            };

        case MIGRATE_USER_FAILED:
            return {
                ...state,
                manualMigrationStatus: "failed",
            };

        case TOGGLE_SOFT_MIGRATION:
            return {
                ...state,
                softMigrationStatus: "loading",
            };

        case TOGGLE_SOFT_MIGRATION_SUCCESS:
            if (!state.user) return state;

            const updatedUser = {
                ...state.user,
                softMigrationEnabled: action.softMigrationEnabled,
            };

            return {
                ...state,
                user: updatedUser,
                softMigrationStatus: "success",
            };

        case TOGGLE_SOFT_MIGRATION_FAILED:
            return {
                ...state,
                softMigrationStatus: "failed",
            };

        case GET_SOFT_MIGRATION_STATUS_SUCCESS:
            if (!state.user) return state;

            const updatedUserWithSoftMigration = {
                ...state.user,
                softMigrationEnabled: action.softMigrationEnabled,
            };

            return {
                ...state,
                user: updatedUserWithSoftMigration,
            };
        case ADD_FREE_SUBSCRIPTION:
            return { ...state, isRedeemingVoucher: true };

        case ADD_FREE_SUBSCRIPTION_SUCCESS:
            return { ...state, isRedeemingVoucher: false };

        case ADD_FREE_SUBSCRIPTION_FAILED:
            return { ...state, isRedeemingVoucher: false };

        default: {
            return state;
        }
    }
}

function capitalize(word: string) {
    return word[0].toUpperCase() + word.slice(1);
}

export default ManageUsersReducer;
