import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Heading,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { PaymentTransaction } from "../redux/types";
import { format } from "date-fns";

interface Props {
    groupedPaymentTransactions: { year: string; transactions: PaymentTransaction[] }[];
}

function PaymentTransactions({ groupedPaymentTransactions }: Props) {
    if (!groupedPaymentTransactions || groupedPaymentTransactions?.length === 0) return <></>;

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h3" fontSize="1.25rem" fontWeight="700">
                Vipps/Mobile pay transactions
            </Heading>
            <Flex direction="column" gridGap="0.5rem">
                {groupedPaymentTransactions.map(({ year, transactions }, idx) => (
                    <Flex flexDirection="column" key={idx} backgroundColor="#e9e9e9" rounded="0.75rem" border="1px solid #ccc">
                        <Accordion allowToggle key={year}>
                            <AccordionItem border="none">
                                <AccordionButton
                                    padding="0.75rem 1rem"
                                    rounded="0.75rem"
                                    transition="border-radius 0.5s ease-in-out"
                                    sx={{
                                        "&[aria-expanded=true]": {
                                            rounded: "0.75rem 0.75rem 0 0",
                                        },
                                    }}
                                    _focus={{ outline: "none" }}
                                >
                                    <Box as="span" flex="1" fontWeight="700" textAlign="left">
                                        {year}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel p="0">
                                    <Table borderColor="#cfcfcf">
                                        <Thead fontSize="sm">
                                            <Tr>
                                                <Th width="20%" textAlign="left" background="#cfcfcf">
                                                    <Text>Id</Text>
                                                </Th>
                                                <Th width="20%" textAlign="center" background="#cfcfcf">
                                                    <Text>Amount</Text>
                                                </Th>
                                                <Th width="20%" textAlign="center" background="#cfcfcf">
                                                    <Text>Status</Text>
                                                </Th>
                                                <Th width="20%" textAlign="right" background="#cfcfcf">
                                                    <Text>Created date</Text>
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody fontSize="sm">
                                            {transactions?.map((trans) => {
                                                return (
                                                    <Tr>
                                                        <Td
                                                            width="20%"
                                                            textAlign="left"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {trans.paymentTransactionId}
                                                        </Td>
                                                        <Td
                                                            width="20%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {trans.amount} {trans.currency}
                                                        </Td>
                                                        <Td
                                                            width="20%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {trans.state}
                                                        </Td>
                                                        <Td
                                                            width="20%"
                                                            textAlign="right"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {format(new Date(trans.createdAt), "yyyy-MM-dd HH:mm")}
                                                        </Td>
                                                    </Tr>
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
}

export default PaymentTransactions;
