import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { MdArrowBack, MdThumbUpOffAlt } from "react-icons/md";
import { createAxiosRequest, createPostAxiosRequest, createPatchAxiosRequest } from '../../utils';
import { Box, Heading, Checkbox, Text, Button, CheckboxGroup } from "@chakra-ui/react";
import { actions as podcastActions } from "../../ManagePodcasts/redux";
import CircularProgress from "../../components/CircularProgress";
import CountDown from './Countdown';

interface MigrateViewProps {
    podcastId: number;
}

const podcastViewState = (state: { managePodcasts: { podcastView: any; }; }) => state.managePodcasts.podcastView;

export default function MigrateView({ podcastId }: MigrateViewProps) {
    const dispatch = useDispatch();
    const { podcast } = useSelector(podcastViewState);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [makeEpisodesPremium, setMakeEpisodesPremium] = useState(true);
    const [migrationStatus, setMigrationStatus] = useState({
        batchId: 0,
        encodedEpisodesCount: 0,
        isCancelled: false,
        isCompleted: false,
        lastEpisodeUpdatedAtUTC: "0001-01-01T00:00:00Z",
        createdAtUTC: "0001-01-01T00:00:00Z",
        remainingEpisodesCount: 0,
        totalEpisodesCount: 0,
    });

    const [regions] = useState([
        { id: '1', name: "Sweden" },
        { id: '2', name: "Norway" },
        { id: '3', name: "Finland" },
    ]);

    const [selectedRegionsError, setSelectedRegionsError] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]);

    const checkMigrationStatus = async (id: number) => {
        try {
            setIsLoading(true);
            setHasError(false);
            const res = await createAxiosRequest(`api/podcast-migration/status?podcastId=${id}`);
            const migrationStatusData = res.data;
            setMigrationStatus(migrationStatusData);
            setIsLoading(false);
        }
        catch (err) {
            setHasError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (podcastId) {
            dispatch(podcastActions.getPodcast(podcastId));
            checkMigrationStatus(podcastId);
        }
    }, [podcastId]);

    const handleStartMigration = async () => {
        try {
            if (selectedRegions.length > 0) {
                setSelectedRegionsError(false)
                setIsLoading(true);
                setHasError(false);
                const postData = {
                    podcastId,
                    makeEpisodesPremium,
                    regions: selectedRegions.map(r => parseInt(r, 10))
                };
                await createPostAxiosRequest(`api/podcast-migration/start`, postData);
                await checkMigrationStatus(podcastId);
            } else {
                setSelectedRegionsError(true)
            }

        }
        catch (err) {
            setHasError(true);
            setIsLoading(false);
        }
    };

    const handleContinueMigration = async () => {
        try {
            setIsLoading(true);
            const postData = {
                podcastId,
                makeEpisodesPremium: false, //ignored
                regions: [] //ignored
            };
            await createPostAxiosRequest(`api/podcast-migration/start`, postData);
            await checkMigrationStatus(podcastId);
        }
        catch (err) {
            setIsLoading(false);
        }
    }

    const handleMakeEpisodesPremium = () => {
        const newValue = !makeEpisodesPremium;
        setMakeEpisodesPremium(newValue);
    };

    const handleChangeSelectedRegions = (regions: string[]) => {
        setSelectedRegions(regions);
    };

    const handleCancelMigration = async () => {
        if (window.confirm("Are you sure you want to cancel this migration?")) {
            try {
                setIsLoading(true);
                setHasError(false);
                await createPatchAxiosRequest(`api/podcast-migration/cancel?podcastId=${podcastId}`, null);
                await checkMigrationStatus(podcastId);
            }
            catch (err) {
                setHasError(true);
                setIsLoading(false);
            }
        }
    };

    const handleCheckStatus = () => {
        if (!migrationStatus.isCancelled && !migrationStatus.isCompleted) {
            checkMigrationStatus(podcastId);
        }
    };

    const isVgFeed = (rssLink: string) => {
        if (!rssLink) {
            return false;
        }
        return rssLink.startsWith('https://podcast.stream.schibsted.media/vgtv/');
    };

    const getLocalTime = (dateUTC: string) => {
        var date = new Date(dateUTC);
        return date.toLocaleString();
    }

    const loadingState = () => {
        return <CircularProgress />;
    };

    const errorState = () => {
        return <Box padding={10}>Something went wrong when fetching data. Please try again by reloading the page. If you still see this message after a few retries, feel free to contact support.</Box>;
    };

    const cantMigrateState = () => {
        return (
            <Box padding={10}>
                <Text>
                    It looks like this Podcast is already a Premium Podcast. No need to migrate.
                </Text>
            </Box>
        );
    };

    const beforeStartedState = () => {
        return (
            <div>
                <Text>
                    Clicking <i>start migration button</i> will start a migration job that downloads all of the podcast's episodes from its current host and then store them at PodMe.
                    After that it will encode each of the episodes. When the encoding is done the job is finished. If something is wrong you can cancel the job which will stop the dowloading and encoding.
                    It might take some time for the job to finish, you can follow progress of migration on this page after it has been started.
                </Text>
                <Text mt={4}>
                    <strong>Before migrating ensure the ads are turned off on the current host.</strong>
                </Text>
                {podcast && isVgFeed(podcast.rssLink) && (
                    <Text mt={4}>
                        Looking at the feed URL, {podcast.rssLink}, it looks like this podcast belongs to the VG Network, meaning you don't have to turn off ads.
                        The Migration job will download ad free episodes using a token.
                    </Text>
                )}
                <Box mt={4}>
                    <Checkbox isChecked={makeEpisodesPremium} onChange={handleMakeEpisodesPremium}>
                        Make all episodes premium
                    </Checkbox>
                </Box>
                <Box mt={4}>
                    <Text as='legend' marginBottom={2}>
                        Select to which region(s) the podcast should belong
                    </Text>
                    <CheckboxGroup
                        colorScheme='teal'
                        onChange={handleChangeSelectedRegions}
                    >
                        {regions.map((region) => (
                            <Checkbox key={region.id} value={`${region.id}`}>
                                {region.name}
                            </Checkbox>
                        ))}
                    </CheckboxGroup>
                    {selectedRegionsError && (<Text>Select at least one region</Text>)}
                </Box>


                <Button colorScheme='blue' onClick={handleStartMigration} mt={4}>Start migration</Button>
            </div>
        );
    };

    const ongoingState = () => {
        return (
            <Box padding={10}>
                <Box display="flex" flexDirection="column">
                    <Heading as='h3' size='lg'>
                        Migration ongoing...
                    </Heading>
                    <Text>
                        Migration started at {getLocalTime(migrationStatus.createdAtUTC)}
                    </Text>
                    <Text>
                        <strong>{migrationStatus.encodedEpisodesCount} / {migrationStatus.totalEpisodesCount}</strong> episodes encoded so far. {migrationStatus.encodedEpisodesCount > 0 && (<span>Last encoded at {getLocalTime(migrationStatus.lastEpisodeUpdatedAtUTC)}.</span>)}
                    </Text>
                    <Box display="flex">
                        <Text mr={2}>Updating status in </Text><CountDown onFinish={handleCheckStatus} duration={60} />
                    </Box>
                </Box>

                <Text mt={4} mb={4}>
                    If you want to leave this page while migration is ongoing but want to get back to it later you can use this URL <strong>{window.location.href}</strong>
                </Text>
                <Button colorScheme='red' onClick={handleCancelMigration}>Cancel migration</Button>
            </Box>);
    };

    const cancelledState = () => {
        return (
            <Box padding={4} >
                <Text mb={4}>The migration for this Podcast has been cancelled. Click continue button to continue where it left off.</Text>
                <Button colorScheme='blue' onClick={handleContinueMigration}>Continue migration</Button>
            </Box>
        );
    };

    const completedState = () => {
        return (
            <Box padding={10} display="flex">
                <Text mr={2}>
                    The migration of <strong>{podcast && podcast.title}</strong> has been completed sucessfully
                </Text>
                <MdThumbUpOffAlt fontSize='1.3em' />
            </Box>
        );
    };

    const showCurrentState = () => {
        if (isLoading || !podcast) {
            return loadingState();
        }
        if (hasError) {
            return errorState();
        }
        if (migrationStatus.batchId === 0) {
            return podcast.isPremium ? cantMigrateState() : beforeStartedState();
        }
        if (migrationStatus.isCancelled) {
            return cancelledState();
        }
        if (migrationStatus.isCompleted) {
            return completedState();
        }
        return ongoingState();
    };

    return (
        <Box>
            <Box>
                <Box>
                    <NavLink to={`/podcasts/${podcastId}`}>
                        <Box display='flex'>
                            <MdArrowBack fontSize='1.5em' color='white' />
                            <Text as='span' fontWeight='semibold' paddingLeft={2} color='white'>
                                Back
                            </Text>
                        </Box>
                    </NavLink>
                </Box>
                <Box paddingTop={8} mb={4} display='flex' alignItems='flex-end'>
                    <Text fontSize='lg' color='white' mr={2}>
                        Migrate
                    </Text>
                    <Heading as='h1' color='white' size='lg'>
                        {podcast && podcast.title}
                    </Heading>
                    <Text fontSize='lg' color='white' ml={2}>
                        to PodMe Premium
                    </Text>
                </Box>
            </Box>
            <Box bg='white' rounded={10}>
                <Box padding={4}>
                    {showCurrentState()}
                </Box>
            </Box>
        </Box>
    )
}
