import { Box, Button, Flex, FormControl, FormLabel, Heading, Switch, Text } from "@chakra-ui/react";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FaRegFrownOpen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { colors } from "../../constants";
import LinkedAccounts from "../../ManageUsers/components/LinkedAccounts/LinkedAccounts";
import MasterAccountInfo from "../../ManageUsers/components/MasterAccountInfo";
import SubscriptionList from "../../ManageUsers/components/SubscriptionList";
import UserDetailsSkeleton from "../../ManageUsers/components/UserDetailsSkeleton";
import { actions } from "../../ManageUsers/redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { PodmeColor } from "../../redux/types";
import { getActiveSubscriptions } from "../../utils";
import Breadcrumbs, { BreadcrumbType } from "./components/Breadcrumbs";
import { USER_SEGMENT_TYPE, VoucherRedemptionHistory } from "../../ManageUsers/redux/types";
import UsedVouchers from "../../ManageUsers/components/UsedVouchers";
import MigrationInfo from "../../ManageUsers/components/MigrationInfo";

const manageUsersState = (state: RootState) => state.manageUsers;
const toastMessageState = (state: RootState) => state.toastMessage;

function UserDetails() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, isDeletingUser, userNotFound, manualMigrationStatus } = useSelector(manageUsersState);
    const { errorStatus } = useSelector(toastMessageState);
    const match = useRouteMatch<{ userId: string }>();
    const { softMigrationEnabled } = user || {};

    const userId = match.params.userId;

    const masterAccount = useMemo(() => {
        return user?.accounts.find((acc) => acc.userAccountId === user?.id);
    }, [user?.id]);

    const studentSegment =
        useMemo(() => {
            const segments = user?.segments ?? [];
            return segments.find((acc) => acc.userSegmentType === USER_SEGMENT_TYPE.Student);
        }, [user?.id]) ?? null;

    const voucherRedemptionHistory =
        useMemo(() => {
            var result: VoucherRedemptionHistory[] = [];
            user?.accounts.forEach((a) => {
                if (a.voucherRedemptionHistory) {
                    a.voucherRedemptionHistory.forEach((h) => result.push(h));
                }
                return a.voucherRedemptionHistory ?? [];
            });
            return result;
        }, [user?.id]) ?? null;

    const [selectedAccountId, setSelectedAccountId] = useState<string>("");
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number>(0);
    const [showActiveSubscriptionsOnly, setShowActiveSubscriptionsOnly] = useState<boolean>(true);

    const hasSubscription = useMemo(() => user?.accounts.some((acc) => !!acc.subscriptions.length), [user]);
    const readyToDisplay = !!user && !!masterAccount && softMigrationEnabled !== undefined;

    const subscriptionEvents = useMemo(() => {
        return user?.accounts.map((acc) => acc.subscriptions.map((sub) => sub?.subscriptionEvents)).flat();
    }, [user]);

    const isUserMigrated = useMemo(() => {
        if (!user) return false;
        return user.accounts.some((acc) => acc.migrated || acc.originatingFromSchibsted);
    }, [user]);

    const handleUsersTableRedirect = () => {
        const locationState = history.location.state as { from?: string };
        const previousPath = locationState?.from;

        if (previousPath && previousPath.startsWith("/users?")) {
            history.push(previousPath);
        } else {
            history.push("/users");
        }
    };

    const breadCrumbs = [
        {
            type: "button",
            label: "Users table",
            onClick: handleUsersTableRedirect,
            isCurrentPage: false,
        },
        {
            type: "span",
            label: "User details",
            isCurrentPage: true,
        },
    ] satisfies BreadcrumbType[];

    const hasActiveSubscriptions = useMemo(() => {
        return user?.accounts.some((acc) => !!getActiveSubscriptions(acc.subscriptions).length);
    }, [user]);

    const handleDeleteUser = useCallback((id: string) => {
        dispatch(actions.deleteUser(id));
    }, []);

    const handleSelectAccount = (id: string) => {
        setSelectedAccountId(id);

        setTimeout(() => {
            setSelectedAccountId("");
        }, 2000);
    };

    const handleSelectSubscription = (id: number) => {
        setSelectedSubscriptionId(id);

        setTimeout(() => {
            setSelectedSubscriptionId(0);
        }, 2000);
    };

    const handleFetchUserDetails = useCallback(() => {
        dispatch(actions.getUser(userId));
    }, [userId]);

    const renderContent = useCallback(() => {
        if (!readyToDisplay && !userNotFound) {
            return <UserDetailsSkeleton />;
        }

        if (userNotFound) {
            return (
                <Flex alignItems="center" justifyContent="center" height="calc(100vh - 15rem)">
                    <Flex direction="column" alignItems="center" gridGap="0.5rem">
                        <FaRegFrownOpen color={PodmeColor.Silver} fontSize="2rem" />
                        <Flex direction="column" alignItems="center">
                            <Box as="span" fontWeight="500" fontSize="1rem" color={PodmeColor.Silver}>
                                Couldn't find a user with the ID:
                            </Box>
                            <Box as="span" color={PodmeColor.BlackPearl + "50"} fontWeight="700">
                                {userId}
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            );
        }

        if (errorStatus === 500) {
            return (
                <Flex alignItems="center" justifyContent="center" height="calc(100vh - 15rem)">
                    <Flex direction="column" alignItems="center" gridGap="0.5rem">
                        <FaRegFrownOpen color={PodmeColor.Silver} fontSize="2rem" />
                        <Flex direction="column" alignItems="center" gap="1rem">
                            <Flex direction="column" alignItems="center">
                                <Box as="span" fontWeight="500" fontSize="1rem" color={PodmeColor.Silver}>
                                    An error occurred while fetching user details with the ID:
                                </Box>
                                <Box as="span" color={PodmeColor.BlackPearl + "90"} fontWeight="700">
                                    {userId}
                                </Box>
                            </Flex>

                            <Button
                                width="fit-content"
                                height="auto"
                                rounded="3rem"
                                padding="0.75rem 1.5rem"
                                lineHeight="1.4"
                                colorScheme="cyan"
                                fontWeight="500"
                                bgColor={PodmeColor.Cyan}
                                onClick={handleFetchUserDetails}
                            >
                                Try again
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            );
        }

        if (masterAccount && user) {
            return (
                <>
                    <MasterAccountInfo masterAccount={masterAccount} studentSegment={studentSegment} />
                    <MigrationInfo isMigrated={isUserMigrated} />
                    <Flex direction="column" gridGap="3rem">
                        <Flex direction="column" gridGap="0.5rem">
                            <Flex gridGap="1rem" justifyContent="space-between">
                                <Heading as="h2" fontSize="1.5rem">
                                    Subscriptions
                                </Heading>
                                <FormControl width="auto">
                                    <Flex alignItems="center" gridGap="0.5rem">
                                        <FormLabel htmlFor="showActiveOnly" margin="0">
                                            Show active subscriptions only
                                        </FormLabel>
                                        <Switch
                                            id="showActiveOnly"
                                            isChecked={showActiveSubscriptionsOnly}
                                            onChange={(e) => setShowActiveSubscriptionsOnly(e.target.checked)}
                                            sx={{
                                                "> span[data-checked]": {
                                                    bgColor: PodmeColor.Success + "!important",
                                                },
                                            }}
                                        />
                                    </Flex>
                                </FormControl>
                            </Flex>
                            {!hasSubscription ? (
                                <Text as="span" fontWeight="500" fontSize="1rem" color={PodmeColor.Silver}>
                                    No subscriptions found
                                </Text>
                            ) : !hasActiveSubscriptions && showActiveSubscriptionsOnly ? (
                                <Text as="span" fontWeight="500" fontSize="1rem" color={PodmeColor.Silver}>
                                    No active subscriptions found
                                </Text>
                            ) : (
                                user.accounts.map(
                                    (acc) =>
                                        !!acc.subscriptions.length && (
                                            <SubscriptionList
                                                key={acc.userAccountId}
                                                showInactiveSubscriptions={!showActiveSubscriptionsOnly}
                                                userAccountId={acc.userAccountId}
                                                accountLoginType={acc.loginType}
                                                subscriptions={acc.subscriptions}
                                                selectedId={selectedSubscriptionId}
                                                onSelect={() => handleSelectAccount(acc.userAccountId)}
                                                cancelSubscription={(podcastId?: string) =>
                                                    dispatch(actions.cancelSubscription(acc.userAccountId, podcastId))
                                                }
                                            />
                                        )
                                )
                            )}
                        </Flex>

                        <UsedVouchers
                            voucherRedemptionHistory={voucherRedemptionHistory}
                            onSubmitForRedeemFreeVoucher={(voucherCode: string) =>
                                dispatch(actions.addFreeVoucherSubscription(user.id, voucherCode))
                            }
                        />

                        <Flex direction="column" gridGap="1rem">
                            <Heading as="h2" fontSize="1.5rem">
                                Linked accounts
                            </Heading>
                            <LinkedAccounts
                                disableInactiveSubscriptions={showActiveSubscriptionsOnly}
                                isDeletingUser={isDeletingUser}
                                accountsList={user.accounts}
                                userId={user.id}
                                selectedId={selectedAccountId}
                                onSelect={(id: number) => handleSelectSubscription(id)}
                                deleteUserFn={handleDeleteUser}
                            />
                        </Flex>
                    </Flex>
                </>
            );
        }
        return null;
    }, [
        user?.id,
        subscriptionEvents,
        hasSubscription,
        readyToDisplay,
        userId,
        userNotFound,
        masterAccount?.userAccountId,
        studentSegment,
        isUserMigrated,
        hasActiveSubscriptions,
        showActiveSubscriptionsOnly,
        selectedSubscriptionId,
        selectedAccountId,
        voucherRedemptionHistory,
        errorStatus,
    ]);

    useEffect(() => {
        if (manualMigrationStatus === "success") {
            setTimeout(() => {
                dispatch(actions.getUser(userId));
            }, 2000);
        } else {
            dispatch(actions.getUser(userId));
        }
    }, [manualMigrationStatus]);

    useEffect(() => {
        if (user?.id) {
            dispatch(actions.getSoftMigrationStatus(userId));
        }
    }, [user?.id]);

    useEffect(() => {
        if (user?.accounts.length === 0) {
            history.replace("/users");
        }
    }, [user?.accounts]);

    return (
        <Flex direction="column" gridGap="1rem">
            <Breadcrumbs breadcrumbsArr={breadCrumbs} />
            <Box>
                <Heading as="h1" size={"xl"} color={colors.softWhite}>
                    User details
                </Heading>
            </Box>

            <Flex direction="column" gridGap="3rem" backgroundColor={colors.softWhite} rounded="1rem" padding="2rem 1.5rem">
                {renderContent()}
            </Flex>
        </Flex>
    );
}

export default memo(UserDetails);
