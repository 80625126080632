import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Checkbox,
    Input,
    Box,
    Textarea,
    Heading,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";

import UploadAudioBox from "./UploadAudioBox";
import UploadImageBox from "./UploadImageBox";
import DatePicker from "./DatePicker/DatePicker";

const createEpisodeState = (state) => state.managePodcasts.createEpisode;

export default function UploadEpisode({ onSubmit, resetForm, podcastId, authorId }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoading, uploadSuccessful } = useSelector(createEpisodeState);
    const [useEpisodeImage, setUseEpisodeImage] = useState(false);
    const [formSubmitted, setFormsubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) setFormsubmitted(false);
        resetForm();
    }, [isOpen]);

    useEffect(() => {
        if (uploadSuccessful) {
            onClose();
        }
    }, [uploadSuccessful]);

    return (
        <>
            <Button colorScheme='green' size='md' onClick={onOpen}>
                Upload episode
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='xl'>
                <ModalOverlay backgroundColor='rgba(0,0,0,0.7)' />
                <Formik
                    initialValues={{
                        title: "",
                        audio: "",
                        image: "",
                        description: "",
                        podcastId: podcastId,
                        authorId: authorId,
                        publishDate: new Date(),
                        isPremium: true,
                        isMinicast: false
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (!values.title) {
                            errors.title = "You must enter a title";
                        }

                        if (!values.audio) {
                            errors.audio = "You must add an audio file";
                        }

                        if (values.audio && !values.duration) {
                            errors.duration = "Please wait until duration has been read from audio file";
                        }

                        if (!values.description) {
                            errors.description = "You must enter description";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            onSubmit(values);
                            setSubmitting(false);
                            setFormsubmitted(false);
                        }, 800);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <ModalContent>
                            <>
                                <ModalHeader>
                                    <Heading size='lg'>Upload episode</Heading>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Form>
                                        <Box paddingBottom={6}>
                                            <FormControl isInvalid={errors.title && touched.title} isRequired>
                                                <FormLabel htmlFor='title'>Episode title</FormLabel>
                                                <Input
                                                    id='title'
                                                    placeholder='Episode title'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.title}
                                                />
                                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl isRequired isInvalid={errors.audio && formSubmitted}>
                                                <FormLabel htmlFor='image'>Soundfile</FormLabel>
                                                <UploadAudioBox
                                                    id='audio'
                                                    setValue={(value) => setFieldValue("audio", value)}
                                                    setDuration={(value) => setFieldValue("duration", value)}
                                                    setError={(error) => console.log(error)}
                                                />
                                                <FormErrorMessage>{errors.audio}</FormErrorMessage>
                                                <FormErrorMessage>{errors.duration}</FormErrorMessage>
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl>
                                                <FormLabel htmlFor='image'>
                                                    <Checkbox
                                                        value={useEpisodeImage}
                                                        onChange={(evt) => {
                                                            if (evt.target.checked) {
                                                                setUseEpisodeImage(true);
                                                            } else {
                                                                setUseEpisodeImage(false);
                                                                setFieldValue("image", "");
                                                            }
                                                        }}
                                                        colorScheme='teal'
                                                        isChecked={useEpisodeImage}
                                                    >
                                                        Episode image?
                                                    </Checkbox>
                                                </FormLabel>
                                                {useEpisodeImage && (
                                                    <>
                                                        <UploadImageBox
                                                            id='image'
                                                            setValue={(value) => setFieldValue("image", value.file)}
                                                        />
                                                        <FormHelperText>The image resolution should be 1024x1024</FormHelperText>
                                                        <FormErrorMessage>{errors.image}</FormErrorMessage>
                                                    </>
                                                )}
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl isRequired isInvalid={errors.description && touched.description}>
                                                <FormLabel htmlFor='description'>Description</FormLabel>
                                                <Textarea
                                                    id='description'
                                                    placeholder='Describe the episode'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.description}
                                                />
                                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl>
                                                <FormLabel htmlFor='image'>
                                                    <Checkbox
                                                        value={values.isPremium}
                                                        onChange={(evt) =>
                                                            evt.target.checked
                                                                ? setFieldValue("isPremium", true)
                                                                : setFieldValue("isPremium", false)
                                                        }
                                                        colorScheme='teal'
                                                        isChecked={values.isPremium}
                                                    >
                                                        Premium episode?
                                                    </Checkbox>
                                                </FormLabel>
                                            </FormControl>
                                        </Box>
                                        <Box paddingBottom={6}>
                                            <FormControl>
                                                <FormLabel htmlFor='image'>
                                                    <Checkbox
                                                        value={values.isMinicast}
                                                        onChange={(evt) =>
                                                            evt.target.checked
                                                                ? setFieldValue("isMinicast", true)
                                                                : setFieldValue("isMinicast", false)
                                                        }
                                                        colorScheme='teal'
                                                        isChecked={values.isMinicast}
                                                        isDisabled={!values.isPremium}
                                                    >
                                                        Minicast episode?
                                                    </Checkbox>
                                                </FormLabel>
                                            </FormControl>
                                        </Box>
                                        <Box paddingBottom={6}>
                                            <FormControl isRequired isInvalid={errors.publishDate && touched}>
                                                <FormLabel htmlFor='rss'>
                                                    Select date and time when you want the episode to be published
                                                </FormLabel>
                                                <DatePicker
                                                    defaultValue={values.publishDate}
                                                    setDate={(date) => setFieldValue("publishDate", date)}
                                                />
                                                <FormErrorMessage>{errors.publishDate}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    </Form>
                                </ModalBody>
                                <ModalFooter display='flex' justifyContent='center'>
                                    <Box padding={4}>
                                        <Button
                                            colorScheme='green'
                                            size='lg'
                                            onClick={() => {
                                                handleSubmit();
                                                setFormsubmitted(true);
                                            }}
                                            isLoading={isSubmitting || isLoading}
                                        >
                                            Upload
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
