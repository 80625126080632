const config = {
    BACKEND_API_URL: process.env.REACT_APP_API_URL ?? "https://api.podme.com/admin",
    TENANT_NAME: process.env.REACT_APP_TENANT_NAME,
    SIGN_IN_POLICY_NAME: process.env.REACT_APP_SIGN_IN_POLICY_NAME,
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    SCOPE_ID: process.env.REACT_APP_SCOPE_ID,
    AZURE_PROFILE: process.env.REACT_APP_AZURE_PROFILE,
    FILE_SERVICE: process.env.REACT_APP_FILE_SERVICE,
    PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL ?? "",

    COOKIE_NAME: "podme_admincookie",

    SCHIBSTED_CLIENT_ID: process.env.REACT_APP_SCHIBSTED_CLIENT_ID ?? "",
    SCHIBSTED_CLIENT_SECRET: process.env.REACT_APP_SCHIBSTED_CLIENT_SECRET ?? "",
    SCHIBSTED_ENV: process.env.REACT_APP_SCHIBSTED_ENV ?? "",
    SCHIBSTED_SESSION_DOMAIN: process.env.REACT_APP_SCHIBSTED_SESSION_DOMAIN ?? "",

    SCHIBSTED_CLIENT_ID_SE: process.env.REACT_APP_SCHIBSTED_CLIENT_ID_SE ?? "",
    SCHIBSTED_CLIENT_SECRET_SE: process.env.REACT_APP_SCHIBSTED_CLIENT_SECRET_SE ?? "",
    SCHIBSTED_ENV_SE: process.env.REACT_APP_SCHIBSTED_ENV_SE ?? "",
    SCHIBSTED_SESSION_DOMAIN_SE: process.env.REACT_APP_SCHIBSTED_SESSION_DOMAIN_SE ?? "",

    SCHIBSTED_CLIENT_ID_NO: process.env.REACT_APP_SCHIBSTED_CLIENT_ID_NO ?? "",
    SCHIBSTED_CLIENT_SECRET_NO: process.env.REACT_APP_SCHIBSTED_CLIENT_SECRET_NO ?? "",
    SCHIBSTED_ENV_NO: process.env.REACT_APP_SCHIBSTED_ENV_NO ?? "",
    SCHIBSTED_SESSION_DOMAIN_NO: process.env.REACT_APP_SCHIBSTED_SESSION_DOMAIN_NO ?? "",

    SCHIBSTED_CLIENT_ID_FI: process.env.REACT_APP_SCHIBSTED_CLIENT_ID_FI ?? "",
    SCHIBSTED_CLIENT_SECRET_FI: process.env.REACT_APP_SCHIBSTED_CLIENT_SECRET_FI ?? "",
    SCHIBSTED_ENV_FI: process.env.REACT_APP_SCHIBSTED_ENV_FI ?? "",
    SCHIBSTED_SESSION_DOMAIN_FI: process.env.REACT_APP_SCHIBSTED_SESSION_DOMAIN_FI ?? "",

    SCHIBSTED_REDIRECT_URL: process.env.REACT_APP_SCHIBSTED_REDIRECT_URL ?? "",

    BRAZE_SDK_ENDPOINT: process.env.REACT_APP_BRAZE_SDK_ENDPOINT ?? "sdk.fra-01.braze.eu",
    BRAZE_API_KEY: process.env.REACT_APP_BRAZE_API_KEY ?? "",
    BRAZE_REST_ENDPOINT: process.env.REACT_APP_BRAZE_REST_ENDPOINT ?? "https://rest.fra-01.braze.eu",
    BRAZE_REST_API_KEY: process.env.REACT_APP_BRAZE_REST_API_KEY ?? "",
};

//
// WILL BE DELETED LATER:
//
// if (isDev) {
//     config.BACKEND_API_URL = process.env.REACT_APP_API_URL ?? "https://api-test.podme.com/admin";
//     config.FILE_SERVICE = "https://podme-file-service-dev.azurewebsites.net";
//     config.APPLICATION_ID = "237cb924-76f9-4cf7-af8d-6aa9f638652c";
// }

// if (isFeature) {
//     config.BACKEND_API_URL = process.env.FEATURE_BACKEND_API_URL ?? "https://api-feature.podme.com/admin";
//     config.FILE_SERVICE = "https://podme-file-service-dev.azurewebsites.net";
//     config.APPLICATION_ID = "fb6bad19-3c3b-4fce-ab4e-8be20bfdebba";
// }

export default config;
