import { Flex, Heading, Box } from "@chakra-ui/react";
import { UserAccount, UserSegment } from "../../redux/types";
import { format, parseISO } from "date-fns";

interface Props {
    masterAccount: UserAccount;
    studentSegment: UserSegment | null;
}

function MasterAccountInfo({ masterAccount, studentSegment }: Props) {
    const { email, userAccountId, accountType, originatingFromSchibsted, externalAccount } = masterAccount;

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h2" fontSize="1.5rem">
                Master account
            </Heading>
            <Flex gridGap="2rem" bgColor="#eee" rounded="1rem" padding="1.5rem" border="1px solid #e0e0e0">
                <Flex direction="column" gridGap="1.5rem">
                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            USER MAIL
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {email}
                        </Heading>
                    </Flex>

                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            USER ID
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {userAccountId}
                        </Heading>
                    </Flex>
                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            ACCOUNT TYPE
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {accountType}
                        </Heading>
                    </Flex>
                </Flex>
                <Flex direction="column" gridGap="1.5rem">
                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            ORIGINATING FROM SCHIBSTED
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {originatingFromSchibsted ? "Yes" : "No"}
                        </Heading>
                    </Flex>
                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            EXTERNAL ACCOUNT
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {externalAccount ? "Yes" : "No"}
                        </Heading>
                    </Flex>
                    <Flex direction="column" gap="0.25rem">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                            VERIFIED STUDENT
                        </Box>
                        <Heading as="h2" fontSize="1rem">
                            {studentSegment !== null
                                ? `Valid until ${format(parseISO(studentSegment?.validUntil), "yyyy-MM-dd")}`
                                : "No"}
                        </Heading>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default MasterAccountInfo;
